import { useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom'; // For React Router

const useGoogleAnalytics = (title) => {
    const location = useLocation();
    const prevDeps = useRef({ title: null, href: null });
    const effectiveTitle = title || document.title;
  
    useEffect(() => {

        const timer = setTimeout(() => {

            if (prevDeps.current.title !== document.title && prevDeps.current.href !== window.location.href) {
                prevDeps.current = { title: document.title, href: window.location.href };
    
                if (effectiveTitle) {
        
                    // Log the page view to Google Analytics
                    window.gtag('event', 'page_view', {
                        page_title: effectiveTitle,
                        page_path: location.pathname,
                    });
                
                    // console.log('[PAGE VIEW TRACKER] Page view logged:', {
                    //     page_title: effectiveTitle,
                    //     page_path: window.location.href,
                    // });
                }
            }
            prevDeps.current = { title: document.title, href: window.location.href };
            // console.log("[PAGE VIEW TRACKER] [CURRENT] ", prevDeps.current);
        }, 1000);

        return () => clearTimeout(timer);
    }, [effectiveTitle, location.pathname]);
  };
  

export default useGoogleAnalytics;
