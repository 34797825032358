function scramble(text, seed) {
    const rng = seededRandom(seed);
    const textArray = text.split('');
    
    // Fisher-Yates Shuffle (modern algorithm)
    for (let i = textArray.length - 1; i > 0; i--) {
        const j = Math.floor(rng() * (i + 1));
        [textArray[i], textArray[j]] = [textArray[j], textArray[i]];
    }

    return textArray.join('');
}
function unscramble(scrambledText, seed) {
    const rng = seededRandom(seed);
    const indices = Array.from(Array(scrambledText.length).keys());

    // Fisher-Yates Shuffle to determine the original positions
    for (let i = indices.length - 1; i > 0; i--) {
        const j = Math.floor(rng() * (i + 1));
        [indices[i], indices[j]] = [indices[j], indices[i]];
    }

    // Create the original array by reversing the shuffle
    const originalText = Array(scrambledText.length);
    for (let i = 0; i < indices.length; i++) {
        originalText[indices[i]] = scrambledText[i];
    }

    return originalText.join('');
}

// Simple seeded random number generator
function seededRandom(seed) {
    let x = Math.sin(seed) * 10000;
    return function() {
        x = Math.sin(x) * 10000;
        return x - Math.floor(x);
    };
}

export { unscramble, scramble }