import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import useGoogleAnalytics  from "../utils/use-google-analytics"

const Terms = () => {
    useGoogleAnalytics();

    return (<>
    
    <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="title" content="Terms and Conditions | MathPapers.org"/>
        <meta name="description" content="Review the terms and conditions for using MathPapers.org. We offer educational content without requiring user accounts or collecting personal data."/>
        <meta name="keywords" content="terms and conditions, MathPapers.org, user agreement, legal terms"/>
        <meta name="robots" content="index, follow"/>
        <title>Terms and Conditions | MathPapers.org</title>
    </Helmet>
    <div className="w-full pt-24">
    <Header/>
        <div class="max-w-4xl mx-auto py-12 px-6">
            <h1 class="text-4xl font-bold text-center text-blue-600 mb-8">Terms and Conditions</h1>

            <section class="mb-12">
                <p class="text-lg leading-relaxed">Welcome to MathPapers.org. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully. If you do not agree with any part of these terms, you must not use our website.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">Use of Website</h2>
                <p class="text-lg leading-relaxed">MathPapers.org provides educational content, including blogs and activities, free of charge. You may use the content for personal, non-commercial purposes only. You may not modify, distribute, or reproduce the content without our written permission.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">No User Accounts Required</h2>
                <p class="text-lg leading-relaxed">We do not require users to create accounts or provide personal information to access our content. The only data collected is through Google Analytics for website improvement purposes, which includes basic request logging such as IP address, browser type, and page views.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">Google Analytics</h2>
                <p class="text-lg leading-relaxed">We use Google Analytics to gather data on how users interact with our website. This data helps us improve the user experience and the quality of our content. For more information, please refer to our <a href="/privacy-policy" class="text-blue-600 underline">Privacy Policy</a>.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">Intellectual Property</h2>
                <p class="text-lg leading-relaxed">All content on MathPapers.org, including text, graphics, and logos, is the property of MathPapers.org or its content suppliers and is protected by copyright laws. You may not use any content from our site for commercial purposes without obtaining a license from us.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">Limitation of Liability</h2>
                <p class="text-lg leading-relaxed">MathPapers.org provides the content on this site "as is" and makes no warranties, express or implied, regarding the accuracy or completeness of the content. We shall not be liable for any damages arising from the use or inability to use our website.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">Changes to These Terms</h2>
                <p class="text-lg leading-relaxed">We reserve the right to update or modify these terms and conditions at any time without prior notice. Any changes will be posted on this page with an updated effective date. Your continued use of the site after any changes indicates your acceptance of the new terms.</p>
            </section>

            <section class="mb-12">
                <h2 class="text-2xl font-semibold text-blue-500 mb-4">Contact Us</h2>
                <p class="text-lg leading-relaxed">If you have any questions about these terms and conditions, please contact us at <a href="mailto:contact@mathpapers.org" class="text-blue-600 underline">contact@mathpapers.org</a>.</p>
            </section>
        </div>
        <Footer/>
    </div>
    </>)

}

export default Terms;