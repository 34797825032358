import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import useGoogleAnalytics  from "../utils/use-google-analytics"

const Contact = () => {
    useGoogleAnalytics();
    return (
        <div className="w-full pt-24">
            <Header/> 
            <div style={{height: '80vh'}} class="max-w-2xl mx-auto py-12 px-6">
                <h1 class="text-4xl font-bold text-center text-blue-600 mb-8">Contact Us</h1>
                
                <p class="text-lg text-center mb-8">We'd love to hear from you! For any questions, suggestions, or feedback, please reach out to us via email.</p>
                
                <div class="text-center">
                    <a href="mailto:contact@mathpapers.org" class="text-xl text-blue-600 font-semibold underline">contact@mathpapers.org</a>
                </div>
            </div>
            
            <Footer/>
        </div>
    )

}

export default Contact;