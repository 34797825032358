import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import games from "../games1.json"
import categories from "../categories.json"
import rankings from "../rankings.json"
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import Modal from "../Components/Modal";
import { unscramble, scramble }  from "../utils/random"
import { useLocation } from 'react-router-dom'
import { blogs } from "../Data/data";

import Blog1 from "./Blogs/Blog1";
import Blog2 from "./Blogs/Blog2";
import Blog3 from "./Blogs/Blog3";
import Blog4 from "./Blogs/Blog4";
import Blog5 from "./Blogs/Blog5";
import Blog6 from "./Blogs/Blog6";
import Blog7 from "./Blogs/Blog7";
import Blog8 from "./Blogs/Blog8";
import Blog9 from "./Blogs/Blog9";
import Blog10 from "./Blogs/Blog10";
import Blog11 from "./Blogs/Blog11";
import Blog12 from "./Blogs/Blog12";
import Blog13 from "./Blogs/Blog13";
import Blog14 from "./Blogs/Blog14";
import Blog15 from "./Blogs/Blog15";
import Blog16 from "./Blogs/Blog16";
import Blog17 from "./Blogs/Blog17";
import Blog18 from "./Blogs/Blog18";
import Blog19 from "./Blogs/Blog19";
import Blog20 from "./Blogs/Blog20";
import useGoogleAnalytics from "../utils/use-google-analytics";


const MAXIMUM_RELATED_GAMES_TO_SHOW = 10;

const Game = () => {
  let { name } = useParams();

  const location = useLocation()
  let  hour  = location.state ? location.state.hour : null;

if (!hour && hour !== 0) {
   let date = new Date();
   hour = date.getHours() * 60 * 60 + date.getMinutes() + 60 + date.getSeconds();
  }

  let originalName = name;

  name = name.slice(6);
  name = name.substring(0, name.length - 6);

  name = unscramble(name, hour);
  const game = games.find((item) => item.name.replace(/\s+/g, "-") === name);
  const categoryArray = categories[name] || [];

  let imageUrl = game ? game.potential_images[game.image_to_choose] : "";
  let imageUrlSplit = imageUrl.split(".");
  let imageType = "image/" + imageUrlSplit[imageUrlSplit.length - 1];

  const [views, setViews] = useState("1K");

  function pseudoRandomGenerator(seed) {
    let s = Math.sin(seed) * 10000; 
    return s - Math.floor(s);
  }

  function random(c) {
    let indexOfGame = games.indexOf(game);
    let seed = c / (indexOfGame + 1);
    return pseudoRandomGenerator(seed);

  }

  const turnIntoTitle = title => {
    let result = "";
    let words = title.split("-");
    words.forEach((word) => {
      result = result + word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });

    return result;
  }

  function ip2int(ip) {
    return ip.split('.').reduce(function(ipInt, octet) { return (ipInt<<8) + parseInt(octet, 10)}, 0) >>> 0;
  }

  function decimalToThousands(decimal) {
    return ((decimal * 10) + "").slice(0,3) + "K"
  }

  const [hide, setHide] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [relatedGames, setRelatedGames] = useState([]);

  // In the event that this is a misdirection, misdirection actually does not get logged since its overwriten by the page title in the child component useGoogleAnalytics hook.
  // Hooks cannot be called conditionally so this was unavoidable. Its fine because all our misdirection pages will have (Beginner) at the end.
  let analyticsTitle = game ? game.name + " - Mathpapers" : "MISDIRECTION";
  useGoogleAnalytics(analyticsTitle)


  useEffect(() => {
    fetch('https://soccerai.pro/getInfo')
      .then(response => response.json())
      .then(data => {
          let ipAddress = data.ip;
          let intIp = ip2int(ipAddress);
          let randomDecimal = random(intIp);
          let viewCount = decimalToThousands(randomDecimal);
          setViews(viewCount);

          fetch("https://soccerai.pro/getInteractions", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({user_id: localStorage.getItem("like_tracking_token"), game_id: game.name})
          })
          .then(response => response.json()).
          then(data => {
            
            if(data["latest_interaction"] === "like") {
              setIsLiked(true)
              setIsDisliked(false)
              
            } else if (data["latest_interaction"] === "dislike") {
              setIsDisliked(true);
              setIsLiked(false);
            }
          }).catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
          console.log('Error:', error);
          setViews("?K")
      });

      let relatedGames = games.filter(game => {
        if (game.name === name) {
          return false;
        }
        let categoriesOfGame = categories[game.name] || [];
        if (!categories[game.name]) {
          console.log("NO CATEGORY");
          console.log(game.name);
        }
        for(let i = 0; i < categoryArray.length; i++) {
          if (categoriesOfGame.includes(categoryArray[i])) {
            return true;
          }
        }
        return false;
      });
      relatedGames.sort((gameA, gameB) => {
        return (rankings[gameA.name] || 10000) - (rankings[gameB.name] || 10000)
      });

      relatedGames = relatedGames.splice(0, MAXIMUM_RELATED_GAMES_TO_SHOW);
      setRelatedGames(relatedGames);
      
      // Add keydown event listener to stop arrow key scrolling
  const preventArrowKeyScroll = (event) => {
    if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)) {
      event.preventDefault(); // Stop scrolling
    }
  };

  // Attach the event listener
  window.addEventListener("keydown", preventArrowKeyScroll);

  // Focus on the game iframe when it's loaded
  const gameIframe = document.getElementById("game-iframe");
  if (gameIframe) {
    gameIframe.focus();
  }

  return () => {
    // Clean up the event listener on component unmount
    window.removeEventListener("keydown", preventArrowKeyScroll);
  };
  }, []);

  if (!game) {
    let blogsReact = [
      <Blog1 image={`${window.location.origin}/${blogs[0].img}`} description={blogs[0].desc} title={blogs[0].title + " (Beginner)"}/>,
      <Blog2 image={`${window.location.origin}/${blogs[1].img}`} description={blogs[1].desc} title={blogs[1].title + " (Beginner)"}/>,
      <Blog3 image={`${window.location.origin}/${blogs[2].img}`} description={blogs[2].desc} title={blogs[2].title + " (Beginner)"}/>,
      <Blog4 image={`${window.location.origin}/${blogs[3].img}`} description={blogs[3].desc} title={blogs[3].title + " (Beginner)"}/>,
      <Blog5 image={`${window.location.origin}/${blogs[4].img}`} description={blogs[4].desc} title={blogs[4].title + " (Beginner)"}/>,
      <Blog6 image={`${window.location.origin}/${blogs[5].img}`} description={blogs[5].desc} title={blogs[5].title + " (Beginner)"}/>,
      <Blog7 image={`${window.location.origin}/${blogs[6].img}`} description={blogs[6].desc} title={blogs[6].title + " (Beginner)"}/>,
      <Blog8 image={`${window.location.origin}/${blogs[7].img}`} description={blogs[7].desc} title={blogs[7].title + " (Beginner)"}/>,
      <Blog9 image={`${window.location.origin}/${blogs[8].img}`} description={blogs[8].desc} title={blogs[9].title + " (Beginner)"}/>,
      <Blog10 image={`${window.location.origin}/${blogs[9].img}`} description={blogs[9].desc} title={blogs[9].title + " (Beginner)"}/>,
      <Blog11 image={`${window.location.origin}/${blogs[10].img}`} description={blogs[10].desc} title={blogs[10].title + " (Beginner)"}/>,
      <Blog12 image={`${window.location.origin}/${blogs[11].img}`} description={blogs[11].desc} title={blogs[11].title + " (Beginner)"}/>,
      <Blog13 image={`${window.location.origin}/${blogs[12].img}`} description={blogs[12].desc} title={blogs[12].title + " (Beginner)"}/>,
      <Blog14 image={`${window.location.origin}/${blogs[13].img}`} description={blogs[13].desc} title={blogs[13].title + " (Beginner)"}/>,
      <Blog15 image={`${window.location.origin}/${blogs[14].img}`} description={blogs[14].desc} title={blogs[14].title + " (Beginner)"}/>,
      <Blog16 image={`${window.location.origin}/${blogs[15].img}`} description={blogs[15].desc} title={blogs[15].title + " (Beginner)"}/>,
      <Blog17 image={`${window.location.origin}/${blogs[16].img}`} description={blogs[16].desc} title={blogs[16].title + " (Beginner)"}/>,
      <Blog18 image={`${window.location.origin}/${blogs[17].img}`} description={blogs[17].desc} title={blogs[17].title + " (Beginner)"}/>,
      <Blog19 image={`${window.location.origin}/${blogs[19].img}`} description={blogs[18].desc} title={blogs[18].title + " (Beginner)"}/>,
      <Blog20 image={`${window.location.origin}/${blogs[19].img}`} description={blogs[19].desc} title={blogs[19].title + " (Beginner)"}/>,

    ]
    return blogsReact[Math.floor(Math.random() * 20)];
  }

  const handleLikeOrDislike = async(isLike) => {
    let interactionType = "";
    if (isLike) {
      interactionType = "like";
    } else {
      interactionType = "dislike"
    }

    fetch('https://soccerai.pro/interact', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user_id: localStorage.getItem("like_tracking_token"), interaction_type: interactionType, game_id: game.name})
    }).then(res => res.json()).then(data => {
        if (interactionType === "like") {
          setIsLiked(true);
          setIsDisliked(false);
        } else {
          setIsDisliked(true);
          setIsLiked(false);

        }
    }).catch(e => {
      console.log("error " + e);
    });


  }

  return (
    <>
      <Helmet>
        <title>{blogs[Math.floor(Math.random() * 20)].title}</title>
        <meta name="description" content={`A fun and interactive math activity that allows students to learn through engagement and fun!`}/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content={blogs[Math.floor(Math.random() * 20)].title}/>
        <meta property="og:description" content={`A fun and interactive math activity that allows students to learn through engagement and fun!`}/>
        <meta property="og:url" content={window.location.href}/>
        <meta property="article:modified_time" content={new Date().toJSON()}/>
        <meta property="og:image" content={imageUrl}/>
        <meta property="og:image:width" content="100"/>
        <meta property="twitter:image" content={imageUrl}/>
        <meta property="twitter:image:src" content={imageUrl}/>
        <meta property="og:image:height" content="100"/>
        <meta name="twitter:image:height" content="100" data-react-helmet="true"/>
        <meta name="twitter:image:width" content="100" data-react-helmet="true"/>
        <meta property="og:image:type" content={imageType}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:label1" content="Est. reading time"/>
        <meta name="twitter:data1" content="5 minutes"/>
        <meta property="twitter:url" content={window.location.href}/>
        <meta property="twitter:title" content={blogs[Math.floor(Math.random() * 20)].title}/>
        <meta property="twitter:description" content={`A fun and interactive math activity that allows students to learn through engagement and fun!`}/>
        <meta property="og:type" content="article"/>
        <meta name="msapplication-TileImage" content={imageUrl}/>
      </Helmet>

      <Header isScrollable={true} />

      <Modal gameId={game.name} setHide={setHide} hide={hide}/>
      <div className="w-full pt-32">
        <div style={{height: '90vh'}} className="w-full h-screen">
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"></meta>
        <div style={{margin: 'auto', width: '90%', height: '90%'}}>
          <iframe
            id="game-iframe"
            src={game.url}
            className="w-full h-full"
            allowFullScreen
            frameborder="0"
            title={game.name}
          ></iframe>
          <div className="inline-flex bg-gray-500 rounded-3xl cursor-pointer my-4">

            <div style={isLiked ? {color: "rgba(165, 180, 252)"} : {}} onClick={() => handleLikeOrDislike(true)} c className="flex items-center p-3 hover:text-indigo-300">
              <FontAwesomeIcon className="w-8 h-8 mx-3" icon={faThumbsUp} /> 
              <span>{views}</span>
            </div> 
            
            <div className="border-black border-solid border ml-2"></div>

            <div style={isDisliked ? {color: "rgba(165, 180, 252)"} : {}} onClick={() => handleLikeOrDislike(false)} className="flex items-center p-3 hover:text-indigo-300">
              <FontAwesomeIcon className="w-8 h-8 mx-3" icon={faThumbsDown} />
            </div>

            <div className="border-black border-solid border ml-2 mr-2 flex items-center"></div>

            <div onClick={() => setHide(!hide)} className="flex items-center p-3 hover:text-indigo-300">
              <FontAwesomeIcon className="w-8 h-8 mx-3" icon={faFlag} /> <span>Feedback</span>
            </div>
          </div>

          </div>
        </div>
        <div>
          {(relatedGames.length > 0) && <h3 className="text-center font-semibold text-3xl">Similar Games</h3>}
          <br/>
          <div className="flex flex-wrap sm:pl-10 pl-0 md:justify-start justify-center items-start gap-4 mb-8 m-auto w-4/5">
            {(relatedGames).map(game => {
              let gameName = "axhz12" + scramble(game.name.replace(/\s+/g, "-"), hour) + "bcn97g"

              return (
                <div className="game-box-square" style={{width: "150px", height: "150px", margin: '1.5rem'}}>
                    <Link
                      to={`/worksheet/${gameName}?from=${originalName}`}
                      className=""
                      state={{ hour }}
                    >
                      <img
                      style={{borderRadius: '2rem', border: 'solid white 5px'}}
                        src={game.image_to_choose === -1 ? game.potential_images[0]: game.potential_images[game.image_to_choose]}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </Link>
                    <h5 style={{margin: 'auto', textAlign: 'center', paddingTop: '0.5rem'}}>{turnIntoTitle(game.name)}</h5>
                  </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Game;
