import Header from "../../Components/Header";
import { Helmet } from 'react-helmet';
import useGoogleAnalytics  from "../../utils/use-google-analytics"

const Blog4 = ({image, description, title}) => {
  useGoogleAnalytics();


  let imageUrlSplit = image.split(".");
  let fileExtension = imageUrlSplit[imageUrlSplit.length - 1];
  let imageType = `image/${fileExtension}`

  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta property="og:type" content="article"/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>
      <meta property="og:url" content={window.location.href}/>
      <meta property="article:modified_time" content={new Date().toJSON()}/>
      <meta property="og:image" content={image}/>
      <meta property="og:image:width" content="100"/>
      <meta property="twitter:image" content={image}/>
      <meta property="twitter:image:src" content={image}/>
      <meta property="og:image:height" content="100"/>
      <meta name="twitter:image:height" content="100" data-react-helmet="true"/>
      <meta name="twitter:image:width" content="100" data-react-helmet="true"/>
      <meta property="og:image:type" content={imageType}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:label1" content="Est. reading time"/>
      <meta name="twitter:data1" content="5 minutes"/>
      <meta property="twitter:url" content={window.location.href}/>
      <meta property="twitter:title" content={  title + " - Mathpapers" }/>
      <meta property="twitter:description" content={`${title} is a fun and interactive math activity that allows students to learn through engagement and fun!`}/>
      <meta property="og:type" content="article"/>
      <meta name="msapplication-TileImage" content={image}/>
    </Helmet>
    <div className="w-full py-40 xl:px-40 lg:px-28 md:px-14 px-8">
      <Header />
      <h1 className="text-center xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-semibold">
        Equivalent Fractions Made Simple: A Beginner's Guide
      </h1>
      <p className="lg:text-xl text-center mt-10">
        Fractions are numbers that are used to represent parts of wholes.
        Sometimes that have dissimilar numerators and denominators can represent
        the same amount. Such fractions are known as equivalent fractions.
        Let&apos;s learn how to identify such equivalent fractions and how to
        generate them with step-by-step examples.
      </p>
      <div className="mt-20">
        <h1 className="xl:text-3xl lg:text-2xl text-xl md:text-start text-center font-semibold">
          Equivalent fractions
        </h1>
        <p className="lg:text-xl lg:w-3/4 mt-4 md:text-start text-center">
          The following diagrams show a fraction wall. Could you use the
          fraction wall to find out fractions that are equivalent to ⅔?
        </p>
      </div>
      <div className="mt-10 w-full flex justify-center">
        <img
          src="/assets/images/equivalent_fractions.png"
          className="rounded-lg lg:w-1/2"
          alt=""
        />
      </div>
      <p className="lg:text-xl mt-10 text-center">
        If we shade ⅔ on the bar that is split into 3 parts, and compare it with
        the rest of the bars, we can see that 4/6 and 6/9 will be portions of
        the bar that are of equal amount (length). ⅔ , 4/6, and 6/9 are
        therefore equivalent fractions.
      </p>
      <div className="mt-10 w-full flex justify-center">
        <img
          src="/assets/images/eq.png"
          className="rounded-lg lg:w-1/2"
          alt=""
        />
      </div>
      <p className="lg:text-xl mt-10 text-center">
        We can understand this using fraction circles too. The first represents
        ¾ of a circle, and the second is 9/12, but both sectors indicate the
        same part of the circle. Hence ¾ and 9/12 are equivalent.{" "}
      </p>
      <div className="mt-10 w-full flex justify-center">
        <img
          src="/assets/images/equ.png"
          className="rounded-lg lg:w-1/2"
          alt=""
        />
      </div>
      <div className="mt-10">
        <h1 className="xl:text-3xl lg:text-2xl text-xl md:text-start text-center font-semibold">
          Identifying equivalent fractions
        </h1>
        <p className="lg:text-xl lg:w-3/4 mt-4 md:text-start text-center">
          To determine if two fractions are equivalent fractions, we can use a
          fraction wall or represent it using fraction circles as illustrated
          earlier. But this may not always be feasible. Imagine having to draw a
          fraction circle or fraction bar to indicate 12/77, it would be
          impractical to draw a bar with 77 identical segments or a circle with
          77 equal slices. <br />
          <br />
          Let&apos;s try to identify properties of equivalent fractions
        </p>
      </div>

      <div className="mt-10 w-full flex justify-center">
        <img
          src="/assets/images/equivalent fractions.png"
          className="rounded-lg lg:w-1/2"
          alt=""
        />
      </div>
      <p className="lg:text-xl mt-10 md:text-start text-center">
        You can see that multiplying the numerator and denominator of by 2
        results in , and by 3 results in . So if it is possible to obtain a
        fraction by multiplying the numerator and denominator by a whole number,
        then it can be identified as equivalent. This concept is used when you
        need to generate equivalent fractions.
      </p>
      <div className="mt-6">
        <h1 className="xl:text-3xl lg:text-2xl text-xl md:text-start text-center font-semibold">
          Generating equivalent fractions
        </h1>
        <p className="lg:text-xl mt-2 mb-4 md:text-start text-center">
          We can generate equivalent fractions by multiplying or dividing both
          the numerator and the denominator of a fraction by a number. For
          example, let&apos;s consider the fraction 4/10. The following
          illustration shows how we can generate two equivalent fractions.
        </p>
        <div className="md:pl-12 pl-8 mt-8">
          <p className="lg:text-xl  relative lg:before:top-3 before:content-[''] before:w-1.5 before:h-1.5 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-2.5">
            One of the equivalent fractions is generated by dividing the numerator
            and denominator by 2.
          </p>
          <p className="lg:text-xl  relative lg:before:top-3 before:content-[''] before:w-1.5 before:h-1.5 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-2.5">
            The second equivalent fraction is generated by multiplying the
            numerator and denominator by 3.
          </p>          
          <p className="lg:text-xl  relative lg:before:top-3 before:content-[''] before:w-1.5 before:h-1.5 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-2.5">
          There are many other equivalent fractions that you can generate
            using this rule.
          </p>          
        </div>
      </div>
      <div className="mt-10 w-full flex justify-center">
        <img
          src="/assets/images/equivalent example.png"
          className="rounded-lg lg:w-1/2"
          alt=""
        />
      </div>
      <div className="mt-10">
        <h1 className="xl:text-3xl lg:text-2xl text-xl md:text-start text-center font-semibold">
          Simplest equivalent form
        </h1>
        <p className="lg:text-xl lg:w-3/4 mt-4 md:text-start text-center">
          The simplest equivalent form is the equivalent fraction of a fraction
          with the smallest integers as the numerator and denominator. To
          simplify a fraction to its lowest equivalent form we can repeatedly
          divide the numerator and denominator by their common factors until we
          can&apos;t anymore.{" "}
        </p>
      </div>
      <div className="mt-10 w-full flex justify-center">
        <img
          src="/assets/images/equivalent_generation.png"
          className="rounded-lg lg:w-1/2"
          alt=""
        />
      </div>
      <p className="lg:text-xl lg:w-3/4 mt-4 md:text-start text-center">
        Instead of dividing repeatedly, we can divide the numerator and
        denominator by the LCM to directly obtain the fraction in its lowest
        equivalent form. As shown above, the LCM of 54 and 72 is 18, hence we
        can divide 54 and 72 by 18 to generate the simplest equivalent fraction
        of 54/72.
      </p>
    </div>
    </>

  );
};

export default Blog4;
